import { ApiOptions } from "~/old-app/types/api";
import { useGetStaticPageQuery, useUserProfileQuery } from "./user-api";

// useUserProfileQuery,
export const userUserProfile = (options?: ApiOptions) => {
  const { data, isLoading, isFetching } = useUserProfileQuery(
    undefined,
    options
  );

  return {
    profile: data?.response.data,
    isLoading,
    isFetching,
  };
};

// useGetStaticPageQuery,

export const userGetStaticPage = (category?: string) => {
  const { data, isLoading, isFetching } = useGetStaticPageQuery(category, {
    skip: !category,
  });

  return {
    page: data?.response.data,
    isLoading,
    isFetching,
  };
};
