import { Response } from "~/old-app/types/api";
import { Api } from "../api-store";
import {
  UpdateUserSettingsBody,
  User,
  UserForgetPasswordBody,
  UserLoginByOtpBody,
  UserRegisterByOtpBody,
  UserResetPasswordMobileBody,
  UserSignInResponse,
} from "./user-types";
import { dispatch } from "~/old-app/redux";
import { AuthActions } from "~/old-app/redux/auth-slice";
import { setCookie } from "~/old-app/utils/helpers/cookieManager";
import { ModalActions } from "~/old-app/redux/modal-slice";
import { CookieEnum } from "~/old-app/constants";
import { ConfigActions } from "~/old-app/redux/config-slice";

export const UsersApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    userProfile: builder.query<Response<User>, void>({
      providesTags: [],
      query: () => ({
        url: "/user/profile",
        method: "GET",
      }),

      transformResponse: (response: Response<User>) => {
        dispatch(AuthActions.setUser({ user: response.response?.data }));
        dispatch(
          ConfigActions.setConfig({
            // district_id: response.response?.data?.address?.district_id,
            location: response.response?.data?.address?.location,
            selectedAddress: response.response?.data?.address,
            address_id: response.response?.data?.address?.id,
          })
        );
        return response;
      },
    }),

    getStaticPage: builder.query<Response<any>, string | undefined>({
      providesTags: [],
      query: (category) => ({
        url: `/static-pages`,
        method: "GET",
        params: { category },
      }),
    }),

    loginByOtp: builder.mutation<
      Response<{
        mobile: string;
        user_exists: boolean;
      }>,
      UserLoginByOtpBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/login-by-otp`,
        method: "POST",
        body: JSON.stringify(data),
      }),

      transformResponse: (
        response: Response<{
          mobile: string;
          user_exists: boolean;
        }>
      ) => {
        dispatch(
          AuthActions.setIsExistAccount({
            isExistAccount: !!response.response?.data?.user_exists,
          })
        );
        return response;
      },
    }),

    updateUserSettings: builder.mutation<
      Response<{
        mobile: string;
        user_exists: boolean;
      }>,
      UpdateUserSettingsBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/update-setting`,
        method: "PUT",
        body: JSON.stringify(data),
      }),

      transformResponse: (
        response: Response<{
          mobile: string;
          user_exists: boolean;
        }>
      ) => {
        return response;
      },
    }),
    verifyLoginByOtp: builder.mutation<
      Response<UserSignInResponse>,
      UserLoginByOtpBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/verify-login-by-otp`,
        method: "POST",
        body: JSON.stringify(data),
      }),
      transformResponse: (response: Response<UserSignInResponse>) => {
        setCookie(CookieEnum.Token, response.response?.data?.token, {
          expires: 7,
        });
        dispatch(
          AuthActions.login({
            user: response.response?.data.user,
            accessToken: response.response?.data?.token,
            role: "user",
            isExistAccount: response.response?.data?.user_exists,
          })
        );

        // dispatch(ConfigActions.setLanguage(response?.response?.data?.user));

        // dispatch(
        //   ModalActions.setAuthModal({
        //     isVerifyOtpOpen: false,
        //     isSignUpOpen: !response.response?.data?.user_exists,
        //   })
        // );
        // if (response.response?.data?.user_exists) {
        //   window.location.reload();
        // }
        return response;
      },
    }),
    registerByOtp: builder.mutation<
      Response<UserSignInResponse>,
      UserRegisterByOtpBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/register-by-otp`,
        method: "POST",
        body: JSON.stringify(data),
      }),
      transformResponse: (response: Response<UserSignInResponse>) => {
        setCookie(CookieEnum.Token, response.response?.data?.token, {
          expires: 7,
        });
        dispatch(
          AuthActions.login({
            user: response.response?.data.user,
            accessToken: response.response?.data?.token,
            role: "user",
            isExistAccount: response.response?.data?.user_exists,
          })
        );

        // dispatch(
        //   ModalActions.setAuthModal({
        //     isVerifyOtpOpen: false,
        //     isSignUpOpen: false,
        //   })
        // );

        return response;
      },
    }),
    forgetPassword: builder.mutation<Response<{}>, UserForgetPasswordBody>({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/forget-password`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    resetPassword: builder.mutation<Response<{}>, UserForgetPasswordBody>({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/password/reset`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    updatePassword: builder.mutation<Response<{}>, UserForgetPasswordBody>({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/update-password`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    verifyCode: builder.mutation<Response<{}>, UserForgetPasswordBody>({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/password/verify-code`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),

    resetPasswordMobile: builder.mutation<
      Response<{}>,
      UserResetPasswordMobileBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/password/reset/mobile`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    updateProfile: builder.mutation<
      Response<User>,
      UserResetPasswordMobileBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/user/update-profile`,
        method: "PATCH",
        body: JSON.stringify(data),
      }),
      transformResponse: (response: Response<User>) => {
        dispatch(AuthActions.setUser({ user: response.response?.data }));
        return response;
      },
    }),

    // delete /user/delete-account
    deleteAccount: builder.mutation<Response<{}>, void>({
      invalidatesTags: [],
      query: () => ({
        url: `/user/delete-account`,
        method: "DELETE",
      }),
    }),

    userLogout: builder.mutation<Response<{}>, void>({
      invalidatesTags: [],
      query: () => ({
        url: `/user/logout`,
        method: "DELETE",
      }),
      transformResponse: (response: Response<{}>) => {
        setCookie(CookieEnum.Token, "");
        dispatch(AuthActions.logout());
        window.location.reload();
        return response;
      },
    }),
  }),
});

export const {
  useLoginByOtpMutation,
  useVerifyLoginByOtpMutation,
  useRegisterByOtpMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useVerifyCodeMutation,
  useResetPasswordMobileMutation,
  useDeleteAccountMutation,
  useUserProfileQuery,
  useGetStaticPageQuery,
  useUserLogoutMutation,
  useUpdateUserSettingsMutation,
  useUpdatePasswordMutation,
  useUpdateProfileMutation,
} = UsersApi;
